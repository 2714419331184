<template>
  <div>
    <div class="title">
      <div class="name">
        <h3>会员简介</h3>
        <h5>Member Profile</h5>
      </div>
      <div class="search">
        <input placeholder="专家查找">
        <img src="../../assets/image/icon/search.png">
      </div>
    </div>
    <div class="content">
      <div class="card" v-for="(item,index) in data.records" :key="item.id" @click="toDetail(index)">
        <img v-if="item.pic" :src="item.pic" />
        <div class="text">
          <h3>{{ item.name }}</h3>
          <p></p>
          <p></p>
          <p></p>
          <p>{{ item.brief }}
          </p>
        </div>
      </div>
    </div>
    <pagination v-on:toPage="toPage" :this-page="current" :pages="data.pages"></pagination>
  </div>
</template>

<script>
import pagination from "@/components/public/pagination";

export default {
  name: "mien",
  components: {
    pagination
  },
  data() {
    return {
      data: {},
      current: 1,
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      // this.$http.get('/safe/getArticleList', {
      //   params: {
      //     columnName: '专家风采',
      //     belong: 1,
      //     current: this.current,
      //     size: 5
      //   }
      // }).then((res) => {
      //   console.log(res)
      //   let forms = res.data.records
      //   for (let i in forms) {
      //     forms[i].pic=this.$constContent.imgUrl+forms[i].pic
      //   }
      //   this.data = res.data
      // })
      this.$http.get('/expert/getExpertList', {
        params: {
          current: this.current,
          size: 6
        }
      }).then((res) => {
        console.log(res)
        let forms = res.data.records
        for (let i in forms) {
          if (forms[i].pic) {
            forms[i].pic = this.$constContent.imgUrl + forms[i].pic
          }
        }
        this.data = res.data
        localStorage.setItem('exportList',JSON.stringify(res.data.records))
      })
    },
    toPage(e) {
      this.current = e
      this.getDataList()
    },
    toDetail(index) {
      this.$router.push('/expertDetails')
      localStorage.setItem('exportIndex',index)
      this.$emit('getTitle', '专家详情')
    }
  }
}
</script>

<style scoped>
.title {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.name h3 {
  margin: 0;
}

.name h5 {
  margin-top: 10px;
  margin-bottom: 0;
}

.search {
  width: 30%;
  height: 40px;
  /*position: relative;*/
  margin-top: 15px;
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  padding: 0 10px;
}

.search img {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 12px;
  right: 12px;
}

.content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: calc(50% - 50px);
  margin: 10px 0;
  padding: 20px;
  display: block;
}

.card:hover {
  background-color: #f8f8f8;
}

.card img {
  width: 40%;
  float: left;
  cursor: pointer;
}

.card .text {
  width: calc(60% - 10px);
  padding-left: 10px;
  float: left;
  cursor: pointer;

}

.card .text p {
  font-size: 14px;
  color: #999999;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;

}
</style>
